import React from 'react';
import CloudStorageSearch from 'views/CloudStorageSearch';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { Helmet } from 'react-helmet';

const title = 'Search';

const SearchPage = () => {
  return (
    <>
    <Helmet
      defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
      title={title}
      titleTemplate="%s | WindRate.com"
    />    
    <WithLayout
      component={CloudStorageSearch}
      layout={Main}
    />
    </>
  )
};

export default SearchPage;
