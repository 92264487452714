import React from 'react';
import { SearchHero } from 'components/windrate';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));

const CloudStorageSearch = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SearchHero />
    </div>

  );
};

export default CloudStorageSearch;